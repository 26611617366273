@import "./media";
@import './variables';
//
//@font-face {
//  font-family: 'NotoSans Regular';
//  //src: url('./fonts/Gilroy-Regular.eot');
//  src: url('./fonts/NotoSans-Regular.ttf') format('truetype');
//  //url('./fonts/Gilroy-Regular.woff') format('woff');
//  font-weight: bold;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'NotoSans Bold';
//  //src: url('./fonts/NotoSans-Bold.eot');
//  src: url('./fonts/NotoSans-Bold.ttf') format('truetype');
//  //url('./fonts/NotoSans-Bold.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}

//
//@font-face {
//  font-family: 'Gilroy Black';
//  src: url('./fonts/Gilroy-Black.eot');
//  src: url('./fonts/Gilroy-Black.ttf') format('truetype'),
//  url('./fonts/Gilroy-Black.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Gilroy Extrabold';
//  src: url('./fonts/Gilroy-Extrabold.eot');
//  src: url('./fonts/Gilroy-Extrabold.ttf') format('truetype'),
//  url('./fonts/Gilroy-Extrabold.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Gilroy Heavy';
//  src: url('./fonts/Gilroy-Heavy.eot');
//  src: url('./fonts/Gilroy-Heavy.ttf') format('truetype'),
//  url('./fonts/Gilroy-Heavy.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Gilroy Light';
//  src: url('./fonts/Gilroy-Light.eot');
//  src: url('./fonts/Gilroy-Light.ttf') format('truetype'),
//  url('./fonts/Gilroy-Light.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Gilroy Medium';
//  src: url('./fonts/Gilroy-Medium.eot');
//  src: url('./fonts/Gilroy-Medium.ttf') format('truetype'),
//  url('./fonts/Gilroy-Medium.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Gilroy Thin';
//  src: url('./fonts/Gilroy-Thin.eot');
//  src: url('./fonts/Gilroy-Thin.ttf') format('truetype'),
//  url('./fonts/Gilroy-Thin.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Gilroy UltraLight';
//  src: url('./fonts/Gilroy-UltraLight.eot');
//  src: url('./fonts/Gilroy-UltraLight.ttf') format('truetype'),
//  url('./fonts/Gilroy-UltraLight.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}

*,
*::before,
*::after {
  box-shadow: none;
  outline: none;
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: $color-body;
  font-family: "Noto Sans", sans-serif;
  color: $color-black;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  padding: 0;

  @include respond-to(extra-small-screen) {
    font-size: 14px;
    line-height: 18px;
  }
  @include respond-to(small-screen) {
    font-size: 14px;
    line-height: 18px;
  }
  @include respond-to(medium-screen) {
    font-size: 14px;
    line-height: 18px;
  }
  @include respond-to(large-screen) {
    font-size: 14px;
    line-height: 18px;
  }
}

a {
  text-decoration: none;
  color: $color-navy;

  &:visited {
    // color: $color-blue;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  font-size: 2.1875rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5625rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

p {
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

strong {
  font-size: initial;
  font-weight: initial;
}

.container {
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1290px;
  @include respond-to(extra-small-screen) {
    max-width: 500px;
    padding: 0;
  }
  @include respond-to(small-screen) {
    max-width: 760px;
  }
  @include respond-to(medium-screen) {
    max-width: 990px;
  }
  @include respond-to(large-screen) {
    max-width: 1024px;
  }
  @include respond-to(laptop) {
    max-width: 1200px;
  }
}

button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

[class^="alert"] {
  display: initial;
  text-align: center;
  border-radius: 5px;
  padding: 2px 10px;
  width: 100%;
  line-height: 28px;
}

.status {
  display: flex;
  grid-gap: 20px;
  align-items: center;
  font-size: 0.9375rem;

  &-title {
    color: $color-black;
  }

  &-danger {
    color: $color-red !important;
    display: flex;
    align-items: center;

    &__circle {
      width: 18px;
      height: 18px;
      margin-right: 5px;
      background-color: $color-red !important;
      border-radius: 20px;
    }
  }

  &-danger-client {
    color: $color-red !important;
    display: flex;
    align-items: center;

    &__circle {
      width: 18px;
      height: 18px;
      margin-right: 5px;
      background-color: $color-red !important;
      border-radius: 20px;
    }
  }

  &-success {
    color: $color-green-light !important;
    display: flex;
    align-items: center;

    &__circle {
      width: 18px;
      height: 18px;
      margin-right: 5px;
      background-color: $color-green-light !important;
      border-radius: 20px;
    }
  }

  &-warning {
    color: $color-orange !important;
    display: flex;
    align-items: center;

    &__circle {
      width: 18px;
      height: 18px;
      margin-right: 5px;
      background-color: $color-orange !important;
      border-radius: 20px;
    }
  }

  &-info {
    color: $color-info;
    display: flex;
    align-items: center;

    &__circle {
      width: 18px;
      height: 18px;
      margin-right: 5px;
      background-color: $color-info;
      border-radius: 20px;
    }
  }
}

.underline {
  text-decoration: underline;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.t {
  &-l {
    text-align: left;
  }

  &-r {
    text-align: right;
  }

  &-c {
    text-align: center;
  }
}

.d {
  display: block;

  &-b {
    display: block;
  }

  &-f {
    display: flex;
  }

  &-g {
    display: grid;
  }

  &-n {
    display: none;
  }

  &-i-b {
    display: inline-block;
  }
}

.g {
  &-1 {
    grid-template-columns: 1fr;

    &-2 {
      grid-template-columns: 1fr 2fr;

      &-1 {
        grid-template-columns: 1fr 2fr 1fr;
      }
    }

    &-3 {
      grid-template-columns: 1fr 3fr;

      &-1 {
        grid-template-columns: 1fr 3fr 1fr;
      }
    }
  }

  &-2 {
    grid-template-columns: repeat(2, 1fr);

    &-05 {
      grid-template-columns: 2fr 0.5fr;
    }

    &-1 {
      grid-template-columns: 2fr 1fr;
    }
  }

  &-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.gg {
  @for $i from 1 through 10 {
    &-#{$i * 10} {
      grid-gap: $i * 10px;
    }
  }
}

.gg-md {
  @for $i from 1 through 10 {
    &-#{$i * 10} {
      @include respond-to(extra-small-screen) {
        grid-gap: $i * 2px;
      }
    }
  }
}

.gc {
  &-2 {
    grid-column: 1 / 3;
    @include respond-to(extra-small-screen) {
      grid-column: 1;
    }
  }
}

.f {
  &-w {
    flex-wrap: wrap;
  }

  &-d {
    &-c {
      flex-direction: column;
    }

    &-r {
      flex-direction: row;
    }
  }
}

.a-i {
  &-s {
    align-items: stretch !important;
  }

  &-c {
    align-items: center !important;
  }

  &-f-s {
    align-items: flex-start !important;
  }

  &-f-e {
    align-items: flex-end !important;
  }
}

.j-c {
  &-s {
    justify-content: stretch;
  }

  &-c {
    justify-content: center;
  }

  &-s-b {
    justify-content: space-between;
  }

  &-s-e {
    justify-content: space-evenly;
  }

  &-s-a {
    justify-content: space-around;
  }

  &-f-s {
    justify-content: flex-start;
  }

  &-f-e {
    justify-content: flex-end;
  }
}

// Margin
.m {
  &-auto {
    margin: auto;
  }

  @for $i from 0 through 20 {
    &-#{5 * $i} {
      margin: 5px * $i;
      @include respond-to(extra-small-screen) {
        margin: 3px * $i;
      }
      @include respond-to(small-screen) {
        margin: 3px * $i;
      }
      @include respond-to(medium-screen) {
        margin: 3px * $i;
      }
      @include respond-to(laptop) {
        margin: 3px * $i;
      }
      @include respond-to(large-screen) {
        margin: 4px * $i;
      }
      @include respond-to(big-screen) {
        margin: 5px * $i;
      }
    }
    // Left
    &-l-#{5 * $i} {
      margin-left: 5px * $i;
      @include respond-to(extra-small-screen) {
        margin-left: 3px * $i;
      }
      @include respond-to(small-screen) {
        margin-left: 3px * $i;
      }
      @include respond-to(medium-screen) {
        margin-left: 3px * $i;
      }
      @include respond-to(laptop) {
        margin-left: 3px * $i;
      }
      @include respond-to(large-screen) {
        margin-left: 4px * $i;
      }
      @include respond-to(big-screen) {
        margin-left: 5px * $i;
      }
    }
    // Top
    &-t-#{5 * $i} {
      margin-top: 5px * $i;
      @include respond-to(extra-small-screen) {
        margin-top: 3px * $i;
      }
      @include respond-to(small-screen) {
        margin-top: 3px * $i;
      }
      @include respond-to(medium-screen) {
        margin-top: 3px * $i;
      }
      @include respond-to(laptop) {
        margin-top: 3px * $i;
      }
      @include respond-to(large-screen) {
        margin-top: 4px * $i;
      }
      @include respond-to(big-screen) {
        margin-top: 5px * $i;
      }
    }
    // Right
    &-r-#{5 * $i} {
      margin-right: 5px * $i;
      @include respond-to(extra-small-screen) {
        margin-right: 3px * $i;
      }
      @include respond-to(small-screen) {
        margin-right: 3px * $i;
      }
      @include respond-to(medium-screen) {
        margin-right: 3px * $i;
      }
      @include respond-to(laptop) {
        margin-right: 3px * $i;
      }
      @include respond-to(large-screen) {
        margin-right: 4px * $i;
      }
      @include respond-to(big-screen) {
        margin-right: 5px * $i;
      }
    }
    // Bottom
    &-b-#{5 * $i} {
      margin-bottom: 5px * $i;
      @include respond-to(extra-small-screen) {
        margin-bottom: 3px * $i;
      }
      @include respond-to(small-screen) {
        margin-bottom: 3px * $i;
      }
      @include respond-to(medium-screen) {
        margin-bottom: 3px * $i;
      }
      @include respond-to(laptop) {
        margin-bottom: 3px * $i;
      }
      @include respond-to(large-screen) {
        margin-bottom: 4px * $i;
      }
      @include respond-to(big-screen) {
        margin-bottom: 5px * $i;
      }
    }
    // Top + Bottom
    &-y-#{5 * $i} {
      margin-top: 5px * $i;
      margin-bottom: 5px * $i;
      @include respond-to(extra-small-screen) {
        margin-top: 3px * $i;
        margin-bottom: 3px * $i;
      }
      @include respond-to(small-screen) {
        margin-top: 3px * $i;
        margin-bottom: 3px * $i;
      }
      @include respond-to(medium-screen) {
        margin-top: 3px * $i;
        margin-bottom: 3px * $i;
      }
      @include respond-to(laptop) {
        margin-top: 3px * $i;
        margin-bottom: 3px * $i;
      }
      @include respond-to(large-screen) {
        margin-top: 4px * $i;
        margin-bottom: 4px * $i;
      }
      @include respond-to(big-screen) {
        margin-top: 5px * $i;
        margin-bottom: 5px * $i;
      }
    }
    // Left + Right
    &-x-#{5 * $i} {
      margin-left: 5px * $i;
      margin-right: 5px * $i;
      @include respond-to(extra-small-screen) {
        margin-left: 3px * $i;
        margin-right: 3px * $i;
      }
      @include respond-to(small-screen) {
        margin-left: 3px * $i;
        margin-right: 3px * $i;
      }
      @include respond-to(medium-screen) {
        margin-left: 3px * $i;
        margin-right: 3px * $i;
      }
      @include respond-to(laptop) {
        margin-left: 3px * $i;
        margin-right: 3px * $i;
      }
      @include respond-to(large-screen) {
        margin-left: 4px * $i;
        margin-right: 4px * $i;
      }
      @include respond-to(big-screen) {
        margin-left: 5px * $i;
        margin-right: 5px * $i;
      }
    }
  }
}

// Padding
.p {
  @for $i from 0 through 20 {
    &-#{5 * $i} {
      padding: 5px * $i !important;
    }
    // Left
    &-l-#{5 * $i} {
      padding-left: 5px * $i;
    }
    // Top
    &-t-#{5 * $i} {
      padding-top: 5px * $i;
    }
    // Right
    &-r-#{5 * $i} {
      padding-right: 5px * $i;
    }
    // Bottom
    &-b-#{5 * $i} {
      padding-bottom: 5px * $i;
    }
    // Top + Bottom
    &-y-#{5 * $i} {
      padding-top: 5px * $i;
      padding-bottom: 5px * $i;
    }
    // Left + Right
    &-x-#{5 * $i} {
      padding-left: 5px * $i;
      padding-right: 5px * $i;
    }
  }
}

.w-s {
  &-n {
    white-space: nowrap;
  }
}

.link {
  display: flex;
  text-decoration: underline;
  color: $color-blue;
  cursor: pointer;

  svg {
    fill: #3767d6;
    height: 23px;
    width: 23px;
    margin-left: 10px;
  }

  &:hover {
    text-decoration: none;
  }
}

.line {
  width: 30px;
  border-bottom: 1px solid #000;
  display: block;
}

.full-line {
  width: 100%;
  border-bottom: 0.803835px solid #e2e2e2;
  // border-bottom: 1px solid #000;
  display: block;
}

.buttons {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  grid-gap: 15px;

  @include respond-to(extra-small-screen) {
    padding-bottom: 5px;
  }
}

input,
textarea {
  background: $color-white;
  border: 1px solid $color-gray-light;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: initial;
  line-height: initial;
  color: $color-black;
  padding: 10px 15px;

  &:disabled {
    cursor: no-drop;
    background: none;
    color: #666;
  }
}

input {
  height: 40px;
}

.checkbox {
  --background: #fff;
  --border: #ddd;
  --border-hover: #bbc1e1;
  --border-active: #19446c;
  --tick: #fff;
  position: relative;
  display: flex;

  input,
  svg {
    width: 15px;
    height: 15px;
    display: block;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: var(--background);
    border: none;
    width: 15px;
    height: 15px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

    &:hover {
      --s: 2px;
      --b: var(--border-hover);
    }

    &:checked {
      --b: var(--border-active);
    }

    &:disabled {
      --b: var(--border);
      background: var(--border);
    }
  }

  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border-active));
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    transform: scale(var(--scale, 1)) translateZ(0);

    & + * {
      margin-left: 15px;
    }
  }

  &.path {
    input {
      &:checked {
        --s: 2px;
        transition-delay: 0.4s;

        & + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
      }
    }

    svg {
      stroke-dasharray: var(--a, 86.12);
      stroke-dashoffset: var(--o, 86.12);
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    }
  }

  &.bounce {
    --stroke: var(--tick);

    input {
      &:checked {
        --s: 11px;

        & + svg {
          animation: bounce 0.4s linear forwards 0.2s;
        }
      }
    }

    & > svg {
      --scale: 0;
    }
  }

  &__dropdown {
    // display: none;
    position: absolute;
    padding: 5px 0;
    left: -15px;
    top: 25px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s opacity, 0.3s visibility;
    width: max-content;
    background: $color-white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.9375rem;
    z-index: 1;
  }

  &__btn {
    display: flex;
    padding: 10px 20px;
    cursor: pointer;

    svg {
      fill: $color-navy;
      position: relative;
      width: 15px;
      stroke: none;

      & + * {
        margin-right: 10px;
      }
    }

    &:hover {
      background-color: $color-gray-light;
    }
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  svg {
    width: 100px;

    stop {
      stop-color: $color-blue;
    }
  }
}

.gray {
  color: $color-gray;
}

.green {
  color: $color-green;
}

.blue {
  color: $color-navy;
}

.black {
  color: $color-black;
}

.white {
  color: $color-white;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-padding {
    padding-top: 10px;

    @include respond-to(extra-small-screen) {
      width: 100%;
    }
    @include respond-to(small-screen) {
      width: 100%;
    }
  }

  &-space {
    justify-content: space-between;
  }

  &-center {
    justify-content: center;
  }

  &-flexEnd {
    justify-content: flex-end;
  }

  &-align {
    align-items: flex-start;
  }

  &-wrap {
    flex-wrap: wrap;
  }
}

.t-a {
  &-c {
    text-align: center !important;
  }

  &-l {
    text-align: left !important;
  }

  &-r {
    text-align: right !important;
  }
}

.radio-buttons {
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;

  .radio {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
  }

  .radio input[type="radio"] {
    position: absolute;
    opacity: 0;
  }

  .radio-label {
    color: #000;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .radio input[type="radio"] + .radio-label:before {
    content: "";
    border-radius: 100%;
    background-color: $color-gray-light;
    border: 3px solid $color-gray-light;
    box-sizing: border-box;
    display: table;
    width: 18px;
    height: 18px;
    position: relative;
    top: 0px;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
  }

  .radio input[type="radio"]:checked + .radio-label:before {
    background-color: $color-pink-dark;
    border: 3px solid $color-gray-light;
  }
}

.otp {
  display: grid;
  grid-template-columns: 140px 80px 1fr;
  align-items: center;
  grid-gap: 40px;
  margin-top: 30px;
  @include respond-to(extra-small-screen) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  input {
    width: 80px;
    min-width: 80px;
  }
}

.error {
  color: $color-red;
}

.image {
  border-radius: 5px;
  -o-fit-object: cover;
  object-fit: cover;
  z-index: 1;
  width: 100%;
  height: auto;
  min-height: 100%;
}

.close {
  position: absolute;
  display: grid;
  height: 10px;
  width: 10px;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.fz-14 {
  font-size: 14px;
}

.loading {
  height: 200px;
  display: grid;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
    fill: $color-navy;
  }
}

.disabled {
  cursor: no-drop;
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  background-color: #e3e3e3;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-width: 1px 1px 1px 2px;
  border-color: #777;
  background-color: #4f9d3a;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  border-width: 1px 1px 1px 2px;
  background-color: lighten(#4f9d3a, 20%);
}

::-webkit-scrollbar-track {
  border-width: 0;
}

.indent {
  padding: 0 25px;

  &-l {
    padding-left: 25px;
  }

  &-r {
    padding-right: 25px;
  }
}

.page {
  $this: &;
  display: flex;
  flex-direction: column;
  align-items: center;


  &__title {
    font-weight: 700;
    font-size: 24px;
    color: #17191a;
  }

  &_dark-theme {
    background-color: #1a1a1a;
  }
}

.mortgage-documents-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 19px;
  flex-wrap: wrap;
}

.pointer {
  cursor: pointer;
}

.h100 {
  height: 100vh;
}

.w100 {
  width: 100%;
}

.line {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.message {
  color: #000;
}

.dark-theme {
}

.pages-buttons__group {
  align-items: center;
  display: flex;
  gap: 0 44px;
  justify-content: flex-end;

  @media (max-width: 585px) {
    flex-direction: column-reverse;
    gap: 30px;
  }
}
