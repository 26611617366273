@import "../../../index";

.otp-form {
  padding: 20px;
  background-color: #282828;
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px 0;

  &__title {
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.87);
  }

  &__link {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: #4F9D3A;
    justify-content: flex-start;
    padding: 15px 0;
    cursor: pointer;
  }

  &__light-text {
    font-size: 14px;
    line-height: 18px;
    color: #8E8E93;
  }

  &__phone {
    margin-bottom: 12px;
    color: #ffffff;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 8px 0;
  }

  &__timer {
    color: #8E8E93;
  }

  &__timer > span {
    color: rgba(255, 255, 255, 0.87);
  }

  @media (max-width: 585px) {
    max-width: 100%;
    min-width: 100%;
    border-radius: 0;
  }
}

.step {
  &__item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    max-width: 500px;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
  }
}

.light-theme {
  .otp-form {
    background: #ffffff;

    &__title {
      color: #000000;
    }

    &__light-text {
      color: $dark-theme-middle-grey;
    }

    &__phone {
      color: rgba(0, 0, 0, 0.87);
    }

    &__timer {
      color: #000000;
    }

    &__timer > * {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}


