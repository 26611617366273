.video-call {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 42px;
  width: 100%;
  max-width: 916px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    //padding: 35px 0 150px;
  }

  &__container {
    background: #ffffff;
    width: 100%;
    padding: 40px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    //background: #ffffff;
    width: 100%;
    padding: 40px 15px;
  }
}
