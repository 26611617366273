.btn-forward {
  background-color: #4F9D3A;
  box-shadow: 2px 3px 10px rgba(87, 198, 0, 0.2);
  border-radius: 30px;
  padding: 18px 22px;
  color: #fff;
  display: inline-flex;
  gap: 0 20px;

  &.disabled {
    background: lighten(#4F9D3A, 30%);
  }

  &:hover {
    background: #2D7D2F;
  }

  &__label-container {
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-weight: 700;
    font-size: 16px;
  }

  &__caption {
    font-weight: 400;
    font-size: 12px;
  }

  .svg-right {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 14px;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  @media (max-width: 425px) {
    max-width: 100%;
    width: 100%;
  }
}

.mobile-btn {
  //display: flex;
  //justify-content: end;

  @media (max-width: 425px) {
    margin: 0 15px;
  }
}
