
.frame {
  height: auto;
  background: #F5F6F7;
  position: relative;

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &-title {
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
    }

    &-timer {
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 41px;
  }

  &__navigation {
    position: absolute;
    width: 100%;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.50);
    width: 41px;
    height: 41px;

    svg {
      //width: 24px;
    }
  }
}
