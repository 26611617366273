@import "src/media";

.layout {
  $this: &;

  &__wrapper {
    display: grid;
    grid-template-columns: 307px 552px;
    gap: 0 100px;
    justify-content: center;
    background-color: #1a1a1a;
    padding: 32px 0 58px 0;

    @media (max-width: 991px) {
      grid-template-columns: minmax(0, 552px);
    }
    @media (max-width: 585px) {
      grid-template-columns: 1fr;
    }
  }

  &__main {
    z-index: 1;
    overflow: hidden;

    &_dark-theme {
      background-color: $dark-theme-black;

      * > .page__title {
        color: #ffffff;
      }
    }
  }
}

.page {
  min-height: 100vh;
  height: max(100vh, 100%);
  position: relative;

  &__navigation {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    & > div {
      display: flex;
      justify-content: center;

      &:first-child {
        justify-content: flex-start;
      }

      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  &__navigation-v2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    & > div {
      display: flex;
      justify-content: center;
    }
  }
}

.light-theme {
  .layout__wrapper {
    background-color: #F5F6F7;
  }
}
