@import "../../media";

.home-page {
  &__wrapper {
    background-color: $color-body;
    min-height: calc(100vh - 80px);
    padding: 30px 0;

    ul {
      padding: 0;
    }

    img {
      width: 100%;
      height: auto;
    }

    a {
      text-decoration: none;
      display: inline-block;
    }
  }

  &__container {
    margin: 0 auto;
    max-width: 1360px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  &-contact-badge {
    display: flex;
    gap: 0 8px;

    &__subtitle {
      text-transform: uppercase;
      color: #939393;
      font-size: 10px;
    }
  }

  &__icon-circle {
    display: inline-flex;
    width: 40px;
    height: 40px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}
