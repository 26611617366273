// Colors
$color-main: #064d57;
$color-body: #f6faff;
$color-blue: #73abff;
$color-blue-light: #f0f5f9;
$color-navy: #19446c;
$color-gray: #7d7d7d;
$color-gray-light: #f8f8f8;
$color-gray-dark: #ececec;
$color-gray-middle: #f2f2f2;
$color-green: #4f9d3a;

$color-black: #000;
$color-white: #fff;

$color-white-bg: #F5F6F7;

$color-info: #5aa6d1;
$color-orange: #ff8d3a;
$color-red: rgba(175, 11, 11, 0.94);
$color-lighten-red: rgb(255 34 34 / 23%);
$color-green-light: #41a758;

$color-black-light: #707070;
$color-pink: #bc70ff;
$color-pink-dark: #a9298b;

$transition-ease: all 0.2s ease;

$box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.05);
$box-shadow-hover: 0 0 15px rgba(183, 209, 84, 0.3);
$hero-banner-color: rgba(183, 209, 84, 5);
$box-shadow-card: 0 0 20px rgba(0, 0, 0, 0.1);
$box-shadow-button: 0 5px 20px rgba(0, 0, 0, 0.1);

$dark-theme-white: #e8eaeb;
$dark-theme-light-grey: #b7bbbd;
$dark-theme-middle-grey: #454545;
$dark-theme-dark-grey: #282828;
$dark-theme-black: #1a1a1a;

$dark-theme-alpha-white: rgba(255, 255, 255, 0.06);
$dark-theme-alpha-black: rgba(0, 0, 0, 0.2);

$dark-theme-primary-green: #22400f;
$dark-theme-secondary-green: rgba(114, 191, 68, 0.1);
$dark-theme-secondary-green-hover: rgba(79, 157, 58, 0.1);

$border-radius: 5px;

$h-100: 100vh;

// Typography
$Regular: "NotoSans Regular";
$Bold: "NotoSans Bold";

